import React,{Component} from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from "reactstrap";
import API, { 
  baseHost,
  baseAssetsPath,
  vocabAudioPath,
  VOCAB_WORD_AUDIO_PATH,
  VOCAB_TRANSLATION_AUDIO_PATH
} from "api.js";
// core components
import {store} from 'store/index';
import { thead, tbody } from "variables/general";
import { setAlert, setUser } from 'action/index.js';

const mapStateToProps = (state) => {
   return {
      user: state.user
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setUser: (user) => dispatch(setUser(user))
   };
};

class Vocabulary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total:0,
      page: 0,
      search:'',
      count:30,
      orderby: 'desc',
      vocabCategories: [],
      pageSize:10,
      selectedCategory:'all',
      searchText: '',
      paidUser:0,
      languageId:1,
      offset:0,
      vocabularies:{ rows: [], count: 0 },
      savedVocabulary:{ rows: [], count: 0 },
      categoryName:'all',
      requestedAlphabet:'#',
      categoryId: this.props.match.params.catId !== ":catId"?parseInt(this.props.match.params.catId):0, 
      isCategoryId: this.props.match.params.catId !== ":catId"?true:false,
      whiteListedCategories:["all", "Saved", "Online"],
      purchasePlanModal:false,
      playing:false,
      audioObject:null,
      vocabId:0,
      srNo:1,
      paginatedTotalData:0,
      prevRecordCount:0,
      testPopUpData:{},
      savedVocabIds:[],
      inCorrectCount:0,
      correctClicked:false,
      inCorrectClicked:false,
    };

    this.translationShowTimeoutRef = React.createRef();
    this.translationShowTimeoutRef.current = null
  }

  componentDidMount() {
    let stateData = store.getState();
    // check & state paid user or not
    //console.log("SATE DATA",stateData.user.user_paid_plan_id)
    this.setState({paidUser:((stateData.user.user_paid_plan_id === null) || (stateData.user.user_paid_plan_id === undefined))?0:1});
    // check & state language id
    this.setState({languageId:stateData.user.language_id})
    // fetch all categories
    this.getVocabularyCategory();
    // fetch vocabularies
    this.getVocabularies(0, stateData.user.language_id, "next", ((stateData.user.user_paid_plan_id !== null) || (stateData.user.user_paid_plan_id !== undefined))?0:1);
    // fetch user saved vocabs
    this.fetchUserSavedVocabs(stateData.user.language_id, stateData.user.user_id);

    //console.log("SAVE VOX",this.state.savedVocabIds, this.state.paidUser,stateData.user.user_paid_plan_id)
  }

  componentWillUnmount(){
    this.setState({
      categoryName:"all",
      isCategoryId:false,
      srNo:1,
      page:0,
      offset:0,
      playing:false,
      search:''
    });
  }

  // handler for pagination
  changePagination = (p, pageIs="next") =>{

    let page = this.state.page+p;
    if (pageIs === "next")
      this.setState({srNo:this.state.srNo+1});
    else
      this.setState({
        srNo:this.state.srNo-1,
        paginatedTotalData:this.state.paginatedTotalData -this.state.prevRecordCount
      });

    if (page > 0) {
      this.setState({offset:page, page:page});
      this.getVocabularies(page, this.state.languageId,  pageIs);
    }else{
      this.setState({offset:0, page:0});
      this.getVocabularies(0, this.state.languageId,  pageIs);
    }
  }

  // handle input change & category selection
  changeInput = (e) => {
    let { name, value } = e.target;

    if (name === "selectedCategory"){
      // show pop if user is not paid
      //console.log("CA", this.state.paidUser)
      if (this.state.paidUser === 0){
        this.setState({pageSize:10});
        /*if (this.state.whiteListedCategories.includes(value) === false){
          //this.setState({purchasePlanModal:true});
          return true;
        }*/
      }else{
        this.setState({pageSize:30});        
      }

      this.setState({
        offset:0, 
        page:0,
        categoryName:value,
        paginatedTotalData:0,
        srNo:1
      });

      if (value === "all"){
        this.setState({ [name] : "all" },() => {
          this.getVocabularies(0,this.state.languageId); 
        });
      }else{
        var selectedCategoryObj = this.state.vocabCategories.find((category, index) => category.name === value );
        if (selectedCategoryObj){
          this.setState({ [name] : {"id":selectedCategoryObj.id,"name":value} },() => {
            this.getVocabularies(0, this.state.languageId); 
          });
        }
      }
    }
    else if(name === "search"){
      if (this.state.paidUser === 0)
        this.setState({purchasePlanModal:true})
      else{
        this.setState({ [name] : value },() => {
          if (value === "")
            this.getVocabularies(0);
        });        
      }
    }
  }

  clickSearch = () => {
    if (this.state.paidUser === 0){
      this.setState({purchasePlanModal:true})
      return true;
    }
    this.getVocabularies(0);
  }

  _handleKeyDown = (e) => {
    if (this.state.paidUser === 0){
      this.setState({purchasePlanModal:true})
      return true;
    }

    if (e.key === 'Enter') {
      this.getVocabularies(0);
    }
  }

  // to get all vocabularies
  getVocabularies = async(offset=0,
    languageId=this.state.languageId,
    pageIs="next",
    isPaid=this.state.paidUser) => {
    try{
      //console.log("IS PAID:", isPaid, this.state.paidUser)
      const postData = {
        languageId: languageId?languageId:this.state.languageId,
        pageSize: (isPaid == 0)?this.state.pageSize:30,
        pageOffset: (offset > 0)?(offset*this.state.pageSize):offset,
        selectedCategory: this.state.selectedCategory,
        requestedAlphabet: this.state.requestedAlphabet?this.state.requestedAlphabet:'#',
        fetch_alphabets: false,
        searchText: this.state.search
      };
      //console.log("PD", postData)
      const response = await API({
        method: 'POST',
        url: '/vocab',
        data: postData
      });
      //console.log("HERE", response.data.data)
      if (response.status === 200){
        let data = {'rows':[], 'count':0};
        if (this.state.paidUser === 0){
          data = response.data.data.vocab; //response.data.data;
          if (data === undefined)
            data = response.data.data;
        }
        else
          data = response.data.data;
        this.setState({
          data: data.rows,
          total: data.count,
          prevRecordCount: data.rows.length
        });
        //console.log("OKLD DA",this.state.selectedCategory, response.data.data, data.rows.length, this.state.page, pageIs, this.state.paginatedTotalData, data.count)
        if (pageIs === "next"){
          if (this.state.page <=0)
            this.setState({
              paginatedTotalData:data.rows.length
            })
          else
            this.setState({
              paginatedTotalData:this.state.paginatedTotalData + data.rows.length
            })          
        }
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  // to get all available vocabulary categories
  getVocabularyCategory = async() => {
    try{
      const response = await API({
        method: 'GET',
        url: '/vocabulary_categories'
      });
      if (response.data.success && response.data.data.vocabulary_categories) {
        this.setState({ vocabCategories: response.data.data.vocabulary_categories }, ()=>{
          if(this.state.isCategoryId === true){
            //console.log("JERE",this.state.categoryId,this.state.isCategoryId)
            if (this.state.categoryId === 0){
              this.setState({selectedCategory:"all", categoryName: "all"})
              if (this.state.paidUser)
                this.getVocabularies(0)
            }
            else{
              var selectedCategoryObject = this.state.vocabCategories.find((category, index) => category.id === this.state.categoryId );
              //console.log("ESL",selectedCategoryObject)
              if (selectedCategoryObject){
                this.setState({ selectedCategory: {"id":selectedCategoryObject.id,"name":selectedCategoryObject.name}, categoryName: selectedCategoryObject.name },() => {
                    // show pop if user is not paid
                    if (this.state.paidUser === 0){
                      if (this.state.whiteListedCategories.includes(selectedCategoryObject.name) === false)
                        this.setState({purchasePlanModal:true});
                    }else{
                      this.getVocabularies(0)
                    }
                });
                //console.log("CS", this.state.categoryName)
              }
            }
          }
        });
      } else {
        console.log("Error fetchVocabCategories ==> ");
      }
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  // to fetch user saved vocabs
  fetchUserSavedVocabs = async(languageId, userId) => {
    try{
      const postData = {
        languageId: languageId?languageId:this.state.languageId,
        user_id: userId
      };
      await API({
        method: 'POST',
        url: '/saved/vocabs/list',
        data: postData
      });
    }catch(error) {
      this.setState({error:{error} });
    };
  }

  closePurchasePlanModal = () =>{
    this.setState({purchasePlanModal:!this.state.purchasePlanModal});
  }

  handlePlay = (index,vocabUrl) =>{
    let audio = new Audio(vocabUrl);
    this.setState({audioObject:audio, vocabId:index}, ()=>{      
        this.state.audioObject.play();
        this.setState({playing:true}, ()=>{
          audio.addEventListener("loadeddata", () => {
            let duration = audio.duration;
            setTimeout(()=>{
              this.setState({playing:false});
            }, duration*1000);
          });
        });
    });
  }

  handlePause = (index,vocabUrl) =>{
    this.state.audioObject.pause();
    this.setState({playing:false});    
  }

  // to add particular word as saved/wishlist/important vocabs
  addRemoveSavedVocabulary = async(actionType) => {
      //console.log("JERE")
      const postData = {
        vocab_id: this.state.testPopUpData["id"],
        user_id: store.getState().user.user_id,
        type:actionType
      };
      //console.log("POST", postData)
      let response = await API({
        method: 'POST',
        url: '/saved/vocabs/add_edit',
        data: postData
      });
      //console.log("MSV",response)
      if (response.status === 200){
        if (actionType === "add"){
          //console.log("IN IF")
          this.setState({savedVocabIds:[...this.state.savedVocabIds, this.state.testPopUpData["id"]]});
        }
        else{
          //console.log("IN ELSE")
          let filteredArray = this.state.savedVocabIds.filter(item => item !== this.state.testPopUpData["id"])
          this.setState({savedVocabIds: filteredArray});
        }
        //console.log("AFTER", this.state.savedVocabIds)
        store.dispatch(setAlert({'flag':true,'type':1,'message': response.data.message}));
      }
      else
        store.dispatch(setAlert({'flag':true,'type':2,'message': response.data.message}));
  }

  render() {
    let { count, srNo} = this.state;
    let title = "Vocabulary";
    let i = srNo * count-count;
    return (
      <>
        {this.state.purchasePlanModal && <Modal isOpen={this.state.purchasePlanModal} id="exitPopup" size="md">
        <div className="modal-body">
          <button onClick={(e)=>this.closePurchasePlanModal()} type="button" className="close" data-dismiss="modal">&times;</button>
          <div className="mobileIcon">
            <Card>
              <CardTitle><h3>Buy Plan</h3></CardTitle>
              <CardBody>
                <h4>In order to use this feature, you need to buy plan. </h4>
                <h4><a href="/admin/packages">Click here</a></h4>
              </CardBody>
            </Card>         
          </div>
          <div className="contentInr">    
          </div>
        </div>
        </Modal>}
        {true &&
          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4"><span>{title}</span></CardTitle>
                  </CardHeader>

                  <CardBody>
                    <Row>
                      <Col lg={4} md={4} sm={4}>
                       <label>Search by Keywords</label>
                        <InputGroup style={{width: '100%'}}>
                          <Input placeholder="Search " onKeyDown={(e) => { this._handleKeyDown(e)} } name="search" autoCorrect="false" spellCheck="false" onChange={(e) => { this.changeInput(e)} } value={this.state.search} />
                          <InputGroupAddon addonType="append">
                            <InputGroupText onClick={this.clickSearch} className="btn-primary"><i className="fa fa-search" ></i></InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>

                      <Col lg={4} md={4} sm={4}>
                        <label dd={this.state.categoryName}>Category</label>
                        <select 
                          onChange={this.changeInput}
                          name="selectedCategory"
                          className="form-control"
                          //defaultValue={this.state.categoryName}
                          value={this.state.categoryName}
                          >
                          <option value="all">All</option>
                          {
                            this.state.vocabCategories.map((category, index) => (
                              <option key={index} value={category.name} >{category.name}</option>
                            ))
                          }
                        </select>
                      </Col>
                      {/*<Col lg={4} md={4} sm={4}>
                        <Button 
                            color="primary"
                            className="bottom-btn"
                            onClick={()=>this.setState({showVocabularyTestModal:true})}
                          >
                          Take Test
                        </Button>
                      </Col>*/}
                   </Row>
                   <div className="table-responsive">
                    <Table >
                      <thead className="text-primary">
                        <tr>
                          <th className="w-10">S. No</th>
                          <th className="w-25">Word</th>
                          <th className="w-50">Transalation</th>
                          <th className="w-10">Category</th>
                          <th className="w-5">Audio</th>
                          {/*<th className="">Mark Save/Important</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data !== undefined && this.state.data.map((prop, key) => {                          
                          let audioUrl = ((prop.is_online === undefined) && (this.state.categoryName !== "Online"))?(baseHost + baseAssetsPath + vocabAudioPath + prop.id + ".mp3"):
                                  (baseHost + baseAssetsPath + vocabAudioPath + "online_" + prop.id + ".mp3");
                          return (
                            <tr key={key}>
                              <td className="w-10">{++i}</td>
                              <td className="w-25">{prop.word}</td>
                              <td className="w-50">{prop.translation}</td>
                              <td className="w-10">{(prop.vocabulary_category !== undefined)?prop.vocabulary_category.name:this.state.categoryName}</td>
                              <td className="w-5">
                                {/*<audio src={ ((prop.is_online === undefined) && (this.state.categoryName !== "Online"))?(baseHost + baseAssetsPath + vocabAudioPath + prop.id + ".mp3"):
                                  (baseHost + baseAssetsPath + vocabAudioPath + "online_" + prop.id + ".mp3") } controls="controls" controlsList="nodownload noplaybackrate"  />*/}
                                {
                                  this.state.vocabId === prop.id?(
                                    <Button color="primary" className="bottom-btn playBtn" onClick={(this.state.playing === true)?()=>this.handlePause(prop.id, audioUrl):()=>this.handlePlay(prop.id,audioUrl)} size="sm">
                                        <i className={(this.state.playing === true) ? 'fa fa-pause' : 'fa fa-play'}></i>
                                    </Button>
                                  ):(
                                    <Button color="primary" className="bottom-btn playBtn" onClick={()=>this.handlePlay(prop.id,audioUrl)} size="sm">
                                        <i className={'fa fa-play'}></i>
                                    </Button>
                                  )
                                }

                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {
                      ((this.state.data === undefined) || (this.state.data.length === 0)) && <div className="noRecord mx-auto"><img src="/images/no-record.jpg" alt="not-found" /><h5>No record found</h5></div>
                    }
                    {
                      ((this.state.paidUser === 0) && ((this.state.categoryName !== "Online") && (this.state.categoryName !== "Saved") ))?(
                        <div className="buyBlurContainer" xx={this.state.categoryName}>
                          <img src="/images/blur_vocab.png" alt="blur_vocab"/> 
                          <div className="seeMoreVocab">
                            <h4>In order to see more vocabularies, you need to buy plan. </h4>
                            <a href="/admin/packages" className="btn">Click here</a>
                          </div>
                        </div>
                      ):null
                    }
                    </div>
                    {
                      this.state.paidUser?(
                        <div className="paginationOuter">
                          <Button 
                            color="primary"
                            className="bottom-btn"
                            onClick={()=>this.changePagination(-1, "previous")}
                            size="sm"
                            disabled={this.state.paginatedTotalData === this.state.total?true:false}
                          >
                            <i className="fa fa-arrow-left"></i>
                          </Button>
                          <Button 
                            color="primary"
                            className="bottom-btn"
                            onClick={()=>this.changePagination(1, "next")}
                            size="sm"
                            disabled={this.state.total < 30 ?true:false}
                          >
                            <i className="fa fa-arrow-right"></i>
                          </Button>
                        </div>
                      ):null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vocabulary);
