import React from "react";
import {
  Button,
  Alert,
  Input,
} from "reactstrap";
import { connect } from 'react-redux';
import "components/custom.css";
import API from "api.js";
import {  NavLink,Link } from "react-router-dom";
import { setToken, setUser, setLogo, setSub} from 'action/index.js';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import NotificationAlert from "react-notification-alert";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import reCAPTCHA from 'react-google-recaptcha';
import dotenv from  'dotenv'

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
const mapStateToProps = (state) => {
   return {
      token: state.token
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      setToken: (tok) => dispatch(setToken(tok)),
      setUser: (user) => dispatch(setUser(user)),
      setSub: (sub) => dispatch(setSub(sub)),
      setLogo: (sub) => dispatch(setLogo(sub))
   };
};
class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error_status: this.props.location.pathname === '/auth/login/session-expired' ? true : false,
      error_status_message: 'Multiple Logins Detected! Please Logout from other device or mobile, or Change your Password!',
      email: '',
      password: '',
      login:false,
      error:null,
      error_bag:null,
      loginLock:false,
      taskModal:false,
      task_success_bag:'',
      task_error_bag:'',
      task_name:'',
      task_phone:'',
      task_email:'',
      task_institute:'',
      logoIcon:"/assets/web_portal/logol.png",
      isMainSite: (window.location.hostname.split('.').shift() === "www")?true:false,
      token:"",
      refreshCaptcha:false,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    console.log("======================ENV", process.env.REACT_APP_SITE_KEY)
    ReactRecaptcha3.init(process.env.REACT_APP_SITE_KEY).then(status=>{
      //console.log("=======================REACT RECAP3:", status)
    });
    ReactGA.initialize('UA-157984080-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.init('197333187452630');
    ReactPixel.pageView();
    document.body.classList.toggle("login-page");
    this.props.setToken(null);
    if(this.state.error_status){
      this.notify(2,this.state.error_status_message);
    }
    
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  notify = (type,message) => {
    
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  postLogIn = async() => {
    if (this.state.loginLock) {
      return ;
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
    }
    this.setState({error_bag:null,loginLock:true});
    
    var bodyFormData = new FormData();
    bodyFormData.set('email', this.state.email);
    bodyFormData.set('password', this.state.password);
    //bodyFormData.set('subdomain', window.location.hostname.split('.').shift());
    //console.log("EP",this.state.email, this.state.password)
    let res = null;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    ReactRecaptcha3.getToken().then(async (token) => {
      try{
        console.log("CAPTCH TOKEN", token)
        res = await API({
          method: 'POST',
          url: '/login',
          data: {
            "email":this.state.email,
            "password":this.state.password,
            "token":token
          }
        })
      }catch(error) {
        console.log("LOGIN ERR", error)
      };
      
      //console.log("LOGIN RESP",res, res.data.success)
      if (res.data.success && res.status === 200) {
        const expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60);
        this.props.setToken(res.data.token);
        this.props.setUser(res.data.data.user_data);
        this.props.setLogo(res.data.data.user_data.profile_image);
        let sub  = (res.data.data.user_data.user_paid_plan_end_date === null)?0:1;
        this.props.setSub(sub);
        this.setState({email:'', password:'' });

        window.location.href  = "/admin/dashboard";
      }else if(res.data.success === false){
        this.setState({email:'', password:'' });

        //this.setState({error:res.data.response });
        this.setState({error_bag:res.data.message,loginLock:false});
      }
      else if (res.response.status === 402) {
        this.setState({email:'', password:'' });
        //console.log("IN LAST IF")
        this.setState({error_bag:"Wrong credentials.",loginLock:false});
      }
    }, error =>{
      console.log("TOK ERR", error)
    })
    
  }

  showTaskModal = () => {
    this.setState({taskModal:true});
  }

  closeTaskModal = () => {
    this.setState({taskModal:false});
  }

  submitForm = (e) => {
    e.preventDefault();
    this.postLogIn();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };


  onVerify = async(token) => {
    console.log("TOJKE", token)
    this.setState({token:token});
  };

  doSomething = () => {
    /* do something like submit a form and then refresh recaptcha */
    this.setState({refreshCaptcha:!this.state.refreshCaptcha});
  }
  render() {
    if (this.state.login) {
        //return (<Redirect to="/admin/dashboard" />);
    }
    return (
      <div className="innerPage">
      <div className="login-page">
        <Helmet>
          <title>Login - Language Academy</title>
          <meta name="twitter:title" content="Login - Language Academy" />
          <meta property="og:title" content="Login - Language Academy" />
          <meta name="description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />      
          
          <meta name="twitter:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
          <meta property="og:description" content="Login into your Language Academy account and start practicing for NAATI, PTE and IELTS." />
        </Helmet>
        <div className="notification-div">
          <NotificationAlert ref="notificationAlert" zIndex={9999}/> 
        </div>
        <section className="bannerOuter">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1>Login into your LA's NAATI CCL Practice Portal</h1>
              </div>
            </div>
          </div>
        </section>

        <section className="contentOuter">
          <form onSubmit={this.submitForm}>
          <div className="container">
            <div className="row">
              <div className="col-sm-7 loginForm">
                {this.state.error_bag && <Alert color="warning">{this.state.error_bag}</Alert>}
                {this.state.error_status && <Alert color="danger">{this.state.error_status_message}</Alert>} 
                  <div className="fieldOuter">
                    <Input 
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => { this.changeInput(e)} }
                      />
                      {this.validator.message('Email', this.state.email, 'required|email')}
                  </div>
                  <div className="fieldOuter">
                    <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={(e) => { this.changeInput(e)} }
                        autoComplete="off"
                      />
                      {this.validator.message('Password', this.state.password, 'required')}
                  </div>
                  <div className="rememberForgot">
                    <div className="rememberMe">
                      <input type="checkbox" name="" />
                      <span>Remember me</span>
                    </div>
                    <div className="forgotPwd">
                      <NavLink className="mt-5" to='/auth/forgot'><span>Forgot your Password?</span></NavLink>
                    </div>
                  </div>
                  {/*<div className="fieldOuter text-left">
                    <reCAPTCHA sitekey={"6LfJHXooAAAAAHas6La7t6b1wbJ2GspfEIBWVgVD"} />
                  </div>*/}
                  <div className="fieldOuter text-right">
                    <Button
                      className="themeBtn"
                      onClick={(e) => {this.postLogIn()}}
                    >
                      Login
                    </Button>
                  </div>
              </div>
              { /*!this.state.token && <GoogleReCaptchaProvider reCaptchaKey="6LfJHXooAAAAAHas6La7t6b1wbJ2GspfEIBWVgVD">

                <GoogleReCaptcha
                  onVerify={this.onVerify}
                  refreshReCaptcha={this.state.refreshReCaptcha}
                />
                </GoogleReCaptchaProvider>
              <button type="button" onClick={this.doSomething}>
                Do Something
              </button>
              */
              }
              <div className="col-sm-5 registerLink text-center">
               {
                this.state.isMainSite && 
                  <React.Fragment> 
                    <div className="mb-4">
                      <h3>Login to PTE PORTAL?</h3>
                      <a href="https://www.languageacademy.com.au/auth/login" target="_blank" rel="noopener noreferrer" className="themeBtn blueBtn">Login</a>
                    </div>
                    <div className="mb-0">
                      <h3>New User?</h3>
                      <p>Register now for<br /> NAATI CCL</p>
                      <Link to="/auth/register" className="themeBtn blueBtn">Register</Link>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
          <input  type="submit" style={{display:"none"}} />
          </form>
        </section>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
          }}
        />
      </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
