import axios from 'axios';
//import cookie from 'react-cookies';
import {store} from 'store/index';
import { setToken,setLoader, setSub  } from 'action/index.js';
store.subscribe(listener);
//const base = `https://ladevtesting.languageacademy.com.au`;
// const base = `https://backend22.languageacademy.com.au`;

//let base = `http://127.0.0.1:8000`;

// below for live backend setup
/*
let base = `https://naatiapi.languageacademy.com.au`; // naati base api
let baseHost = `https://assets.languageacademy.com.au/naati/api`; // naati base api
let baseAssetsPath = ``; //`/git/naati_pte/naati/api/`; // naati base api assets
*/
/*
// below for dev backend setup (but dev API connected to live dB): connected with liveWebportal for now
let base = `https://dev-naatiapi.languageacademy.com.au/`; // naati base api
let baseHost = `https://dev-assets.languageacademy.com.au/naati/api/`; // naati base api
let baseAssetsPath = ``; //`/git/naati_pte/naati/api/`; // naati base api assets
*/

// below for testing backend setup (but test API connected to live dB)
let base = `https://testing-naatiapi.languageacademy.com.au/`; // naati base api
let baseHost = `https://dev-assets.languageacademy.com.au/naati/api/`; // naati base api
let baseAssetsPath = ``; //`/git/naati_pte/naati/api/`; // naati base api assets

/*
// below for local setup (adi)
let base = `http://localhost:5019`;
let baseHost = `http://localhost`;
let baseAssetsPath = `/git/naati_pte/naati/api/`; // naati base api assets
*/
let profileImagePath = 'assets/profile_images/';
let vocabAudioPath = 'assets/vocab_audio_files/';
let host = window.location.host;

const ASSETS_SERVER_PATH = 'https://assets.languageacademy.com.au/naati/api/';;
const DEV_ASSETS_SERVER_PATH = 'https://dev-assets.languageacademy.com.au/naati/api/';
const TEST_ASSETS_SERVER_PATH = 'https://test-assets.languageacademy.com.au/api/';
const LOCAL_ASSETS_SERVER_PATH = 'http://localhost/git/naati_pte/naati/api/';
const EXAM_AUDIO_PATH = 'assets/exam_memory_audio_files/';
const MOCK_AUDIO_PATH = 'assets/mock_test_audio_files/';
const MOCK_RESPONSE_PATH = 'assets/mock_responses/';
const MOCK_FEEDBACK_PATH = 'assets/feedback_images/';
const AUDIO_PATH = 'assets/audio_files/';
const WEBPORTAL_PATH = 'assets/web_portal/';
const VOCAB_WORD_AUDIO_PATH = 'assets/vocab_word_audios/';
const VOCAB_TRANSLATION_AUDIO_PATH = 'assets/vocab_translation_audios/';

if (host.includes('la-dev-frontsite-23.languageacademy')) {
	base = `https://ladevtesting.languageacademy.com.au`;
} else if (host.includes('www.languageacademy.com.au')) {
	base = `https://backend22.languageacademy.com.au`;
}

// const base = `https://testing.languageacademy.com.au`;
// const s3base = `https://testing.languageacademy.com.au`;
// const s3base = `http://127.0.0.1:8000`;
const s3base = 'https://s3.ap-southeast-2.amazonaws.com/lamedia21';
const axiosInstance = axios.create({
	baseURL: `${base}/api/v1`
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

function select(state) {
  return state.token
}
function listener() {
  let token = select(store.getState())
  //console.log("API TOKEN", token);
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    // Handle errors
  }
  store.dispatch(setLoader(true));
  console.error("IN ERROR HANDLER:",error);
  if (error.response){
    if(error.response.status === 401){
      store.dispatch(setToken(null));
    }
    if(error.response.status === 402){
      store.dispatch(setSub(false));
    }    
  }
  store.dispatch(setLoader(true));
  return error;
}
const isHandlerEnabled = (config={}) => {
  //console.log("ENABLE HANDLER", config)
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
    false : true
}

const requestHandler = (request) => {
  //console.log("REQ HANDLER", request)
  if (request.url !== "check/payment" && request.url !== "delete/question/responseNew" && request.url !== "check/answer1" && request.url !== "show/history" && request.url !== "show/comment" && request.url !== "show/notes" && request.url !== "submit/mock" && request.url !== 'set/mockTime' && request.url !== 'set/tag' && request.url !== 'dashboard/data2' ) {
    store.dispatch(setLoader(false));
  }
  return request
}

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  store.dispatch(setLoader(true));
  //console.log("SUCCESS",store.getState().token);
  return response
}

export default axiosInstance;
export {base,s3base, baseAssetsPath, baseHost, profileImagePath, vocabAudioPath, ASSETS_SERVER_PATH, EXAM_AUDIO_PATH, AUDIO_PATH, WEBPORTAL_PATH, MOCK_AUDIO_PATH, MOCK_RESPONSE_PATH, MOCK_FEEDBACK_PATH, DEV_ASSETS_SERVER_PATH, TEST_ASSETS_SERVER_PATH, LOCAL_ASSETS_SERVER_PATH,VOCAB_WORD_AUDIO_PATH,VOCAB_TRANSLATION_AUDIO_PATH};
